import axios from '../tools/axiosWrapper';
import { ReactComponent as TechStudiesLogoSVG } from '../media/logo.svg';

import './home.css';

const Home = () => {
    const onClickLoginButton = async () => {
        try {
            const { data } = await axios.get('/auth/login');
            window.location = (data.login_url);
        } catch (error) {
            console.log(error);
        }
    };

    return <>
        <div class="container">
            <div>
                <TechStudiesLogoSVG className="logo" />
            </div>

            <button className="login-button" onClick={onClickLoginButton}>Sign In</button>
        </div>
    </>
};


export default Home;