import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";


const STUDY_COUNT_MIN = 1;
const STUDY_COUNT_MAX = 12;


const Studies = ({ studyCount }) => {
    const { register, formState: { errors } } = useFormContext(); 

    const elements = [];

    for (let i = 0; i < studyCount; i++) {
        const studyIndex = i + 1;
        const isRequired = (i === 0);

        elements.push(
            <div class="paperfield">
                <label className={"paperfield__label " + (isRequired && "required")}>Study Summary #{studyIndex}</label>
                <p class="paperfield__desc">
                    The title and content for the {studyIndex}th study described in the plan.
                    <br />
                    Content must be no more than one paragraph.
                </p>
                <textarea {...register(`summary_study${studyIndex}`, { required: isRequired })}></textarea>
                <br />
                {isRequired && errors[`summary_study${studyIndex}`] && <span>This field is required.</span>}
            </div>
        );
    }

    return elements;
};

const SummarySection = () => {
    const [studyCount, setStudyCount] = useState(1);
    const { register, formState: { errors } } = useFormContext();


    function onClickAddStudy() {
        if (studyCount < STUDY_COUNT_MAX) {
            setStudyCount(studyCount + 1);
        }
    }

    return (
        <>
            <h2>Summary</h2>
            <div class="paperfield">
                <label class="paperfield__label required">Summary Conflict</label>
                <p class="paperfield__desc">
                    This one entry consists of two phrases: the versus statement and the issue statement. 
                    The content is automatically rendered in italics so tag not needed.
                </p>
                <textarea {...register("summary_conflict", { required: true })}></textarea>
                <br />
                {errors.summary_conflict && <span>This field is required.</span>}
            </div>

            <div class="paperfield">
                <label class="paperfield__label required">Summary Text</label>
                <p class="paperfield__desc">
                    One paragraph that describes the plan. No more than one paragraph.
                </p>
                <textarea {...register("summary_text", { required: true })}></textarea>
                <br />
                {errors.summary_text && <span>This field is required.</span>}
            </div>

            <h3>Summary Studies</h3>
            <p>
                Studies are numbered sequentially from 1 with a max of 12,
                and the number is referred to in the writing.  Each plan has at least one study. 
            </p>
            <Studies studyCount={STUDY_COUNT_MAX}></Studies>
            {/* <button type="button" onClick={onClickAddStudy}>Add Study</button> */}
        </>
    );
};


export default SummarySection;