import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

const IntroSection = () => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <>
            <h2>Introduction, Background, Materials and Methods</h2>
            <p>
                Each of these sections will be one or more paragraphs and can extend for pages. 
                Any tags needed for formatting must be applied within the text. 
                Use @placeMediaContent flags to mark the place where an image, video, or sound file appears. 
                Use @placeMediaContent1 for the first media marker, 
                @placeMediaContent2 for the second, and so on.

                You upload the image, video, or sound file in a section near the end of this form.
            </p>

            <div class="paperfield">
                <label class="paperfield__label required">Introduction</label>
                <textarea {...register("introduction", { required: true })}></textarea>
                <br />
                {errors.introduction && <span>This field is required.</span>}
            </div>

            <div class="paperfield">
                <label class="paperfield__label required">Background</label>
                <textarea {...register("background", { required: true })}></textarea>
                <br />
                {errors.background && <span>This field is required.</span>}
            </div>

            <div class="paperfield">
                <label class="paperfield__label required">Materials and Methods</label>
                <textarea {...register("materials_methods", { required: true })}></textarea>
                <br />
                {errors.materials_methods && <span>This field is required.</span>}
            </div>
        </>
    );
};


export default IntroSection;