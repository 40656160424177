import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

const STUDY_COUNT_MIN = 1;
const STUDY_COUNT_MAX = 12;

const StudyFields = ({ studyCount }) => {
    const { register, formState: { errors } } = useFormContext();

    const elements = [];

    for (let i = 0; i < studyCount; i++) {
        const studyIndex = i + 1;
        const isRequired = (i === 0);

        elements.push(
            <div class="paperfield" key={studyIndex}>
                <label className={"paperfield__label " + (isRequired && "required")}>Study #{studyIndex}</label>
                <p class="paperfield__desc">
                    Summary of the {studyIndex}th study described in the plan.
                    Each summary has a title and content that must be no more than one paragraph.
                </p>
                <input type="text" {...register(`studies_title${studyIndex}`, { required: isRequired })} />
                <br />
                {isRequired && errors[`studies_title${studyIndex}`] && <span>This field is required.</span>}
                <br/>

                <textarea {...register(`studies_content${studyIndex}`, { required: isRequired })}></textarea>
                <br />
                {isRequired && errors[`studies_content${studyIndex}`] && <span>This field is required.</span>}
            </div>
        );
    }

    return elements;
};

const EnumeratedStudiesSection = () => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <>
            <h2>Enumerated Studies (Part 2)</h2>
            <StudyFields studyCount={STUDY_COUNT_MAX} />
        </>
    )
};

export default EnumeratedStudiesSection;