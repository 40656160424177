import SubmissionForm from './forms/submission/index';

import React, { useContext, useEffect, useState, useMemo } from "react"

import { ReactComponent as TechStudiesLogoSVG } from './media/logo.svg';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { Navigate } from 'react-router-dom'

import axios from './tools/axiosWrapper';


const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [canAccess, setCanAccess] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        
        axios.get('/auth/me')
            .then(response => {
                if (response === 'false') {
                    setIsLoggedIn(false);
                } else {
                    setIsLoggedIn(true);
                    return axios.get('/auth/status');
                }
            })
            .then(response => {
                const { data } = response;

                setCanAccess(data.can_access);
                setIsAdmin(data.is_admin);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [])

    if (isLoading) {
        return <p>Loading...</p>
    }

    if (!isLoggedIn || !canAccess || !isAdmin) {
        return <p>You are not authorized to access the Tech Studies Submission Portal. Please log in or contact an administrator.</p>
    }

    return <SubmissionForm />;
};


export default Dashboard;