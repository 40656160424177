import React, { useContext, useEffect, useState, useMemo } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { Navigate } from 'react-router-dom'


import axios from '../tools/axiosWrapper'


const SignupForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSignedUp, setIsSignedUp] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [canAccess, setCanAccess] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const methods = useForm();
    const { register, handleSubmit, watch, setValue, formState: { errors } } = methods;

    const onSubmit = (data) => {
        setIsSubmitting(true);
        setIsSaved(false);

        axios.post('/auth/settings', data)
            .then(res => {
                setIsSignedUp(true);
            })
            .then(() => {
                setIsSaved(true);
            })
            .catch(() => {
                setIsSaved(false);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        axios.get('/auth/me')
            .then(res => {
                const { data } = res;

                setValue('first_name', data.first_name);
                setValue('last_name', data.last_name);
                setValue('email', data.email);
                setValue('org_type', data.org_type);
                setValue('org_name', data.org_name);
                setValue('sponsor_email', data.sponsor_email);

                return axios.get('/auth/status');
            })
            .then(res => {
                const { data } = res;

                setCanAccess(data.can_access);
                setIsAdmin(data.is_admin);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [])

    // if (!isSignedUp) {
    //     return <Navigate to='/' />
    // }

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <FormProvider {...methods}>
                <form className='mainform' onSubmit={handleSubmit(onSubmit)}>
                    <div className='mainform-head'>
                    </div>

                    <div class="paperfield">
                        <label class="paperfield__label">First Name</label>
                        <input {...register("first_name", { required: true })} />
                        {errors.first_name && <span>This field is required.</span>}
                    </div>

                    <div class="paperfield">
                        <label class="paperfield__label">Last Name</label>
                        <input {...register("last_name", { required: true })} />
                        {errors.last_name && <span>This field is required.</span>}
                    </div>

                    <div class="paperfield">
                        <label class="paperfield__label">Preferred Email</label>
                        <input {...register("email", { required: true })} />
                        {errors.email && <span>This field is required.</span>}
                    </div>

                    <div class="paperfield">
                        <label class="paperfield__label">Describe Yourself</label>
                        <label htmlFor="field-student">
                            <input
                                {...register("org_type")}
                                type="radio"
                                name="org_type"
                                value="student"
                                id="field-student"
                            />
                            Student
                        </label>
                        <label htmlFor="field-researcher">
                            <input
                                {...register("org_type")}
                                type="radio"
                                name="org_type"
                                value="researcher"
                                id="field-researcher"
                            />
                            Researcher
                        </label>
                        <label htmlFor="field-scholar">
                            <input
                                {...register("org_type")}
                                type="radio"
                                name="org_type"
                                value="scholar"
                                id="field-scholar"
                            />
                            Scholar
                        </label>
                        <label htmlFor="field-journalist">
                            <input
                                {...register("org_type")}
                                type="radio"
                                name="org_type"
                                value="journalist"
                                id="field-journalist"
                            />
                            Journalist
                        </label>
                        {errors.org_type && <span>This field is required.</span>}
                    </div>

                    <div class="paperfield">
                        <label class="paperfield__label">Organization Name</label>
                        <input {...register("org_name", { required: true })} />
                        {errors.org_name && <span>This field is required.</span>}
                    </div>

                    <div class="paperfield">
                        <label class="paperfield__label">Recommender Email</label>
                        <input {...register("sponsor_email", { required: true })} />
                        {errors.sponsor_email && <span>This field is required.</span>}
                    </div>

                    <button type="submit" className="submit-button" disabled={isSubmitting}>
                        {isSubmitting ? "Saving..." : "Save Changes"}
                    </button>
                </form>
            </FormProvider>

            <p>{isSaved ? "Changes saved." : ""}</p>

            
        </>
    );
};


export default SignupForm;