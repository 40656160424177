import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";


// MAX FILESIZE = 10 MB
// Accepted types: PDF, video, audio, image


const MEDIA_COUNT_MAX = 20;

const MediaContentFields = ({ mediaCount }) => {
    const { register, formState: { errors } } = useFormContext();

    const elements = [];

    for (let i = 0; i < mediaCount; i++) {
        let mediaIndex = i + 1;

        elements.push(
            <div class="paperfield">
                <label class="paperfield__label">Media Content #{mediaIndex}</label>
                <p class="paperfield__desc">
                    (Optional) The {mediaIndex}th figure used in the plan.{" "}
                    Has the placeholder @placeMediaContent{mediaIndex}.
                </p>
                {/* <input type="text" {...register(`mediacontent${mediaIndex}`)} /> */}
                <input type="file" {...register(`mediacontent${mediaIndex}`)} />

            </div>
        )
    }

    return elements;
};


const FiguresSection = () => {
    const { register, formState: { errors } } = useFormContext();
    
    return (
        <>
            <h2>Figures</h2>
            <p>
                Upload the image, sound or video files that are referred in the text 
                with @placeMediaContent identifiers. 
                Each place in the document where @placeMediaContent1 appears, for example, 
                will be rendered with the content provided in MediaContent1 below.
            </p>
            <MediaContentFields mediaCount={MEDIA_COUNT_MAX} />
        </>
    )
};


export default FiguresSection;