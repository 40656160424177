import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


import Home from './home/home';
import UserProfile from './user/user';
import Dashboard from './dashboard';

function App() {
  return (
    <>
      <BrowserRouter>
          <Routes>
            <Route path='/'>
              <Route index element={<Home />} />
              <Route path='/user' element={<UserProfile />} />
              <Route path='/dashboard' element={<Dashboard />} />
            </Route>
          </Routes>
      </BrowserRouter>
    </>

  );
}

export default App;
