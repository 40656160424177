import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

const StudiesSection = () => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <>
            <h2>Studies (Part 1)</h2>

            <div class="paperfield">
                <label class="paperfield__label required">Desired Outcome</label>
                <p class="paperfield__desc">This is one or few sentences.</p>
                <textarea {...register("studies_outcome", { required: true })}></textarea>
                <br />
                {errors.studies_outcome && <span>This field is required.</span>}
            </div>

            <div class="paperfield">
                <label class="paperfield__label required">Construct Clause</label>
                <p class="paperfield__desc">
                    This is just the construct clause of the design statement. 
                    The word "Construct" is assumed and will appear in bold. 
                    You do not include the word "construct" below.
                </p>
                <textarea {...register("studies_construct", { required: true })}></textarea>
                <br />
                {errors.studies_construct && <span>This field is required.</span>}
            </div>

            <div class="paperfield">
                <label class="paperfield__label required">"Such That" Clause</label>
                <p class="paperfield__desc">
                    This is just the such that clause of the design statement. 
                    The words "such that" are assumed and will appear in bold. 
                    You do not include the words "such that" below.
                </p>
                <textarea {...register("studies_suchthat", { required: true })}></textarea>
                <br />
                {errors.studies_suchthat && <span>This field is required.</span>}
            </div>

            <div class="paperfield">
                <label class="paperfield__label">Transistion</label>
                <p class="paperfield__desc">
                    (Optional) This text appears after the design statement and before the enumerated studies.
                </p>
                <textarea {...register("studies_transistion")}></textarea>
            </div>
        </>
    );
};


export default StudiesSection;