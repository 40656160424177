import SignupForm from "../forms/signup";
import React, { useContext, useEffect, useState, useMemo } from "react"

import { ReactComponent as TechStudiesLogoSVG } from '../media/logo.svg';
import { Outlet, Link, useNavigate } from "react-router-dom";

import './user.css';


const WorkspaceLink = ({ isAdmin }) => {

    if (isAdmin) {
        return <Link to='/workspace'>Go to Workspace</Link>
    }

    return "";
};


const UserProfile = () => {


    return (
        <div class="container">
            <div>
                <TechStudiesLogoSVG className="logo" />
            </div>
            {/* <WorkspaceLink isAdmin={isAdmin} /> */}

            <h1>Sign Up</h1>
            <SignupForm />
        </div>
    );
};


export default UserProfile;