import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";


const KEYNAME_ISSUES = "keyissues";
const KEYNAME_TECH = "keytech";
const KEYNAME_PROVIDERS = "keyproviders";

const KEYWORDS_ISSUES = ["Algorithmic bias", "COVID", "Deep Fakes", "Disinformation", "Employment discrimination", "Financial transactions", "Free speech", "Gender bias", "Housing discrimination", "Identity theft", "Mental health", "Misinformation", "Pandemic", "Philanthropy", "Phishing", "Phone hacking", "Predictive Policing", "Price discrimination", "Public health surveillance", "Racial or Ethnic bias", "Recidivism", "Speech recognition", "Surveillance"];
const KEYWORDS_TECH = ["Advertising", "AI", "Augmented reality", "Automated translation", "Autonomous vehicles", "Cameras", "Cryptocurrency", "Deep fakes", "Drones", "Face recognition", "Fitness devices", "Government websites", "Image search", "Job recommendation systems", "Mobile devices", "Payment systems", "Personal assistants", "Policing", "Real property", "Robots", "Search engines", "Security devices", "Standardized Test Preparation", "Virtual reality"];
const KEYWORDS_PROVIDERS = ["Airbnb", "Alphabet", "Amazon", "AT&T", "Bitcoin", "Facebook", "Google", "Indeed", "Princeton Review", "T-Mobile", "Twitter", "U.S. Government", "Venmo", "Verizon", "You-tube"];


const KeywordSelectionField = ({ title, keywords, keyname, required=false }) => {
    const { register, formState: { errors } } = useFormContext();
    const elements = [];

    for (let keyword of keywords) {
        const elementId = "id_" + keyword;

        elements.push(
            <>
                <input id={elementId} name={keyname} type="checkbox" {...register(keyname, { required })} value={keyword} />
                <label htmlFor={elementId}>{keyword}</label>
                <br />
            </>
        )
    }

    return (
        <>
            <label className={"paperfield__label " + (required && "required")}>{title}</label>
            {required && errors[keyname] && <span>This field is required.</span> }
            <div class="paperfield">
                {elements}
            </div>
        </>
    );

};


const KeywordsSection = () => {
    return (
        <>
            <h2>Keywords</h2>
            <p class="paperfield__desc">
                The selection and inclusion of keywords appear in the internal document, 
                but are not displayed or rendered with the paper. 
                Keywords are used for searches. 
                Select all that apply to the paper. 
                The selections are grouped into sections for convenience, 
                but this should be considered one big list of keywords. 
            </p>
            <br />

            {<KeywordSelectionField title="Issues" keywords={KEYWORDS_ISSUES} keyname={KEYNAME_ISSUES} required={true} />}
            {<KeywordSelectionField title="Technology" keywords={KEYWORDS_TECH} keyname={KEYNAME_TECH} />}
            {<KeywordSelectionField title="Providers" keywords={KEYWORDS_PROVIDERS} keyname={KEYNAME_PROVIDERS} />}
        </>
    )
};

export default KeywordsSection;