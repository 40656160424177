import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

const DiscussionSection = () => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <>
            <h2>Predicted Events, Discussion and Citation</h2>

            <div class="paperfield">
                <label class="paperfield__label required">Predicted Events</label>
                <p class="paperfield__desc">
                    This is one or more paragraphs that describe likely or possible events based on expectations of experimental results. 
                    The original text may include references to appendices, but no appendices will be included in the paper, so all references to appendices should be removed. 
                    Add any tags needed for formatting.
                </p>
                <textarea {...register("predictions", { required: true })}></textarea>
                <br />
                {errors.predictions && <span>This field is required.</span>}
            </div>

            <div class="paperfield">
                <label class="paperfield__label required">Discussion</label>
                <p class="paperfield__desc">
                    This is one or more paragraphs of discussion about the experiments 
                    and what may be useful even if the experimental results 
                    do not yield the expected answer. 
                    Add any tags needed for formatting.
                </p>
                <textarea {...register("discussion", { required: true })}></textarea>
                <br />
                {errors.discussion && <span>This field is required.</span>}
            </div>

            <div class="paperfield">
                <label class="paperfield__label">Acknowledgements</label>
                <p class="paperfield__desc">
                    (Optional) The following text will automatically appear 
                    as the Acknowledgements unless an alternative text is provided below. 
                    "The original draft of this paper was produced as student work 
                    in one of the Technology Science courses at Harvard University 
                    (Gov1433 in the Faculty of Arts and Sciences or DPI640 
                    in the Harvard Kennedy School). 
                    Editorial changes were subsequently made to the draft."
                </p>
                <textarea {...register("acknowledgements", { required: false })}></textarea>
            </div>

            <div class="paperfield">
                <label class="paperfield__label required">Citation</label>
                <p class="paperfield__desc">
                    The following text will precede the content provided below. 
                    "Be sure to cite this writing in all related and inspired work." 
                    This text will automatically appear, so do not include it below. 
                    Enter the full text of the citation as it should appear. 
                    Use MLA8 style (can use an automated service to generate 
                    such as Easybib to generate. Ignore italics.) 
                    Example: Gargan, Christine. "Investigating Discrimination on Job Recommender Websites." Tech Study Plans. Plan 5006. September 2021. http://techstudies.net
                </p>
                <textarea {...register("citation", { required: true })}></textarea>
                <br />
                {errors.citation && <span>This field is required.</span>}
            </div>
        </>
    )
};

export default DiscussionSection;