import React, { useContext, useEffect, useState, useMemo } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { Navigate } from 'react-router-dom'

import axios from '../../tools/axiosWrapper';

import SummarySection from "./SummarySection";
import IntroSection from "./IntroSection";
import StudiesSection from './StudiesSection';
import EnumeratedStudiesSection from './EnumeratedStudiesSection';
import DiscussionSection from './DiscussionSection';
import ReferenceSection from './ReferenceSection';
import FiguresSection from './FiguresSection';
import KeywordsSection from './KeywordsSection';

import './paperform.css';


const PaperForm = () => {
    const [currentPage, setCurrentPage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [submitError, setSubmitError] = useState("");

    const methods = useForm();
    const { register, handleSubmit, watch, setValue, formState: { errors } } = methods;

    const onSubmit = (data) => {
        setIsSubmitting(true);

        const formData = new FormData();
        for (var key in data) {
            if (key.includes('mediacontent')) {
                if (data[key].length > 0) {
                    formData.append(key, data[key][0])
                } 
                else {
                    formData.append(key, "");
                }
            } else {
                formData.append(key, data[key]);
            }
        }
        
        axios.post('/papers/', formData)
            .then(res => {
                alert("Paper successfully submitted.");
                setSubmitError("");
            })
            .catch(error => {
                setSubmitError(error.response);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };


    // function onChange(event) {
    //     const file = event.target.files[0];
    // }

    return (
        <main>
            <h1>Tech Study Plan (internal submission portal)</h1>
            <p class="paperfield__desc">
                This form is only used by Tech Lab staff to enter reviewed and updated tech study plans.
                Each part of a Tech Study Plan is entered in parts of the form below. 
                The individual parts are then compiled to render a final paper.
            </p>

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h2>General Information</h2>

                    <div class="paperfield">
                        <label class="paperfield__label required">Paper Number</label>
                        <p class="paperfield__desc">Each paper is assigned a unique number for internal purposes. Paper numbers are assigned sequentially, starting at 5001.</p>
                        <input type="text" {...register("papernumber", { required: true })} />
                        <br />
                        {errors.papernumber && <span>This field is required.</span>}
                    </div>

                    <div class="paperfield">
                        <label class="paperfield__label required">Title</label>
                        <p class="paperfield__desc">Enter the title of the paper. No tags needed.</p>
                        <input type="text" {...register("title", { required: true })} />
                        <br />
                        {errors.papernumber && <span>This field is required.</span>}
                    </div>

                    <div class="paperfield">
                        <label class="paperfield__label required">Authors</label>
                        <p class="paperfield__desc">Enter the line as it would appear. No tags needed. Example: Jones A and Baker B</p>
                        <input type="text" {...register("authors", { required: true })} />
                        <br />
                        {errors.authors && <span>This field is required.</span>}
                    </div>

                    <div class="paperfield">
                        <label class="paperfield__label required">Date Line</label>
                        <p class="paperfield__desc">
                            This is the time of year in which the work was produced. 
                            Examples: Spring 2020, Summer 2020, Fall 2020
                        </p>
                        <input type="text" {...register("dateline", { required: true })} />
                        <br />
                        {errors.dateline && <span>This field is required.</span>}
                    </div>

                    <SummarySection />
                    <hr />

                    <IntroSection />
                    <hr />

                    <StudiesSection />
                    <hr />

                    <EnumeratedStudiesSection />
                    <hr />

                    <DiscussionSection />
                    <hr />

                    <ReferenceSection />
                    <hr />

                    <FiguresSection />
                    <hr />

                    <KeywordsSection />
                    <hr />

                    <button class="submit-button" type="submit">{isSubmitting ? "Submitting..." : "Submit"}</button>
                </form>
            </FormProvider>

            <p>
                {submitError ? "ERROR: " + submitError : ""}
            </p>
        </main>
    );
};

export default PaperForm;