import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";


const REFERENCE_COUNT_MIN = 1;
const REFERENCE_COUNT_MAX = 50;

const ReferenceFields = ({ referenceCount }) => {
    const { register, formState: { errors } } = useFormContext();

    const elements = [];

    for (let i = 0; i < referenceCount; i++) {
        const referenceIndex = i + 1;
        const isRequired = (i === 0);

        elements.push(
            <div class="paperfield">
                <label className={"paperfield__label " + (isRequired && "required")}>Reference #{referenceIndex}</label>
                <p class="paperfield__desc">
                    This is the text for Reference #{referenceIndex}, 
                    identified in the paper as <b>[{referenceIndex}]</b>. 
                </p>
                <textarea {...register(`ref${referenceIndex}`, { required: isRequired })}></textarea>
                <br />
                {isRequired && errors[`ref${referenceIndex}`] && <span>This field is required.</span>}

            </div>
        );
    }

    return elements;
};


const ReferenceSection = () => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <>
            <h2>References</h2>
            <ReferenceFields referenceCount={REFERENCE_COUNT_MAX} />
        </>
    );
};

export default ReferenceSection;